import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

function Seo({ title, description, image, url, site }) {
  // Prepare social media seo data.
  const seoSocial = {
    facebook: {
      url: url || site?.url,
      siteName: site?.name,
      appId: site?.facebookAppId,
    },
    twitter: {
      url: url || site?.url,
      site: site?.twitterHandle,
    },
  };

  const imageUrl = image?.[0]?.socialMediaUrl;

  return (
    <Helmet
      title={title}
      defaultTitle={site.defaultTitle}
      titleTemplate={site.titleTemplate}
    >
      {/* Basics */}
      {description && <meta name="description" content={description} />}

      {/* Facebook (Open Graph) */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      {seoSocial.facebook.url && (
        <meta property="og:url" content={seoSocial.facebook.url} />
      )}
      {seoSocial.facebook.siteName && (
        <meta property="og:site_name" content={seoSocial.facebook.siteName} />
      )}
      {seoSocial.facebook.appId && (
        <meta property="fb:app_id" content={seoSocial.facebook.appId} />
      )}

      {/* Twitter */}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      {imageUrl && <meta name="twitter:card" content="summary_large_image" />}

      {seoSocial.twitter.url && (
        <meta name="twitter:url" content={seoSocial.twitter.url} />
      )}
      {seoSocial.twitter.site && (
        <meta name="twitter:site" content={seoSocial.twitter.site} />
      )}
    </Helmet>
  );
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  site: PropTypes.shape({
    name: PropTypes.string,
    titleTemplate: PropTypes.string,
    twitterHandle: PropTypes.string,
    facebookAppId: PropTypes.string,
  }),
};

Seo.defaultProps = {
  title: null,
  description: null,
  url: null,
  site: {
    name: 'Hazel Brugger',
    titleTemplate: '%s – Hazel Brugger',
    twitterHandle: null,
    facebookAppId: null,
  },
};

/**
 * Query:
 * Getting defaults from global data set.
 */
export default props => (
  <StaticQuery
    query={graphql`
      query GlobalSeoQuery {
        site {
          siteMetadata {
            siteUrl
            siteTitle
          }
        }
        craft {
          globals {
            seo {
              twitterHandle: seoTwitterHandle
              facebookAppId: seoFacebookAppId
              titleTemplate: seoTitleTemplate
              defaultTitle: seoTitle
              description: seoDescription
              image: seoImage {
                id
                socialMediaUrl: url(transform: transformSocialMedia)
              }
            }
          }
        }
      }
    `}
    render={data => {
      // Merge defaults with given props so we don't
      // have to handle too much conditional data logic in the component itself
      const mergedProps = {
        site: {
          name: data.site.siteMetadata.siteTitle,
          url: data.site.siteMetadata.siteUrl,
          defaultTitle: data.craft.globals.seo?.defaultTitle,
          titleTemplate: data.craft.globals.seo?.titleTemplate,
          twitterHandle: data.craft.globals.seo?.twitterHandle,
          facebookAppId: data.craft.globals.seo?.facebookAppId,
        },
        ...data.craft.globals.seo,
        ...props,
      };

      return <Seo {...mergedProps} />;
    }}
  />
);
