import React from 'react';
import Link from 'components/Link';
import Wrapper from 'components/Wrapper';
import Headline from 'components/Headline';
import Text from 'components/Text';
import Seo from 'components/Seo';

const NotFoundPage = () => (
  <>
    <Seo title="Fehler 404" />
    <Wrapper>
      <Headline element="h1">Fehler 404</Headline>
      <Text>
        Die angeforderte Seite konnte leider nicht gefunden werden.
        <br />
        <Link to="/">Zur Startseite</Link>
      </Text>
    </Wrapper>
  </>
);

export default NotFoundPage;
